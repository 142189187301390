.bankStatementUploadWrapper {
  display: flex;
  align-items: flex-start;
  margin: .2rem 0 .27rem;
}

@media (max-width: 768px) {
  .bankStatementUploadWrapper {
    flex-direction: column;
    margin: 0 0 .5rem;
    padding: 0;
  }
}

.bankStatementUploadWrapper .inputButtonWrapper {
  width: 100%;
}

.imgContainer {
  display: flex;
  align-items: center;
  margin: 0 0 .1rem;
}

@media (max-width: 768px) {
  .imgContainer {
    justify-content: center;
    margin: 0 0 .2rem;
  }
}

.imgContainer .label {
  margin: 0 .1rem 0 0;
}

.imgContainer .pdfName {
  margin: 0 .1rem 0 0;
}

.imgContainer .clearImg {
  height: .15rem;
  width: .15rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .imgContainer .clearImg {
    height: .5rem;
    width: .5rem;
  }
}

.imgContainer .receiptImg {
  height: 100%;
  width: 100%;
}

.bankStatementUploadButton {
  background: #959595;
  color: #fff;
  margin: 0 0 .15rem;
  width: 1.5rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .bankStatementUploadButton {
    font-size: .38rem;
    margin: 0 0 .2rem;
    width: 100%;
    padding: .25rem;
  }
}