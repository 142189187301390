.passwordEyes {
  position: absolute;
  margin: 0;
  border-radius: 100%;
  padding: .03rem;
  right: 0rem;
  z-index: 1;
  transform: translateY(-50%);
  top: .5rem;
}

@media (max-width: 768px) {
  .passwordEyes {
    transform: translateY(-50%);
    margin: 0 .15rem;
  }
}

.passwordEyes.vertical {
  top: .22rem;
}

@media (max-width: 768px) {
  .passwordEyes.vertical {
    top: .55rem;
  }
}

.passwordEyes.vertical.hasLabel {
  top: .54rem;
}

@media (max-width: 768px) {
  .passwordEyes.vertical.hasLabel {
    top: 1.2rem;
  }
}

.passwordEyes img {
  fill: #afb3b6;
  width: .21rem;
  height: .21rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .passwordEyes img {
    height: .46rem;
    width: .46rem;
  }
}