.reminderNote {
  color: #41b850;
  display: flex;
  align-items: center;
  font-size: .14rem;
}

@media (max-width: 768px) {
  .reminderNote {
    font-size: .35rem;
  }
}

.reminderNote .warningIcon {
  margin: 0 .08rem 0 0;
  height: .18rem;
  width: .18rem;
}

@media (max-width: 768px) {
  .reminderNote .warningIcon {
    margin: 0 .15rem 0 0;
    height: .3rem;
    width: .3rem;
  }
}