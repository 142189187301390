.forceChangePasswordPopup {
  max-width: 5rem;
}

@media (max-width: 768px) {
  .forceChangePasswordPopup {
    max-width: none;
  }
}

.forceChangePasswordPopup .forceChangePasswordContainer .forceChangePasswordFormItemContainer {
  padding: .3rem;
}

@media (max-width: 768px) {
  .forceChangePasswordPopup .forceChangePasswordContainer .forceChangePasswordFormItemContainer {
    padding: .55rem;
  }
}

.forceChangePasswordPopup .forceChangePasswordContainer .forceChangePasswordFormItemContainer .forceChangePasswordFormItem {
  display: grid;
  align-items: center;
  margin: 0 0 .2rem 0;
}

.forceChangePasswordPopup .forceChangePasswordContainer .forceChangePasswordFormItemContainer .forceChangePasswordFormItem .title {
  font-size: .16rem;
  font-weight: 600;
  color: #000;
  margin: 0 0 .1rem;
}

.forceChangePasswordPopup .forceChangePasswordContainer .forceChangePasswordFormItemContainer .forceChangePasswordFormItem .title .asterisk {
  color: red;
}

.forceChangePasswordPopup .forceChangePasswordContainer .forceChangePasswordFormItemContainer .forceChangePasswordFormItem .password {
  display: flex;
  position: relative;
  height: .4rem;
}

.forceChangePasswordPopup .forceChangePasswordContainer .forceChangePasswordFormItemContainer .forceChangePasswordFormItem .password input {
  border-radius: .04rem;
  border: .01rem solid #dadada;
}

.forceChangePasswordPopup .forceChangePasswordContainer .forceChangePasswordFormItemContainer .forceChangePasswordFormItem .password img {
  width: .3rem;
  position: absolute;
  top: .05rem;
  left: 2rem;
  cursor: pointer;
}

.forceChangePasswordPopup .forceChangePasswordContainer .buttonContainer {
  justify-content: center;
  display: flex;
  padding: .3rem .5rem;
  border: .01rem #f5f5f5;
  border-top-style: solid;
}

.forceChangePasswordPopup .forceChangePasswordContainer .buttonContainer .saveButton {
  width: 1.66rem;
  height: .35rem;
  background: #0a974b;
  border-radius: .02rem;
  font-size: .16rem;
  font-weight: 700;
  color: #fff;
  border: 0;
  cursor: pointer;
}

@media (max-width: 768px) {
  .forceChangePasswordPopup .forceChangePasswordContainer .buttonContainer .saveButton {
    width: 5.23rem;
    height: .98rem;
    font-size: .42rem;
  }
}

.forceChangePasswordPopup .forceChangePasswordContainer .buttonContainer .saveButton:disabled {
  opacity: .5;
}