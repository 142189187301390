.standardFormField .fieldLabel {
  font-size: .16rem;
  font-weight: 600;
  margin: 0 0 .1rem 0;
}

@media (max-width: 768px) {
  .standardFormField .fieldLabel {
    font-size: unset;
  }
}

.standardFormField.horizontal {
  display: grid;
  grid-template-columns: 40% 1fr;
}

.errorValidation.horizontal {
  grid-column: 2/3;
}

.formFieldWrapper {
  display: grid;
  grid-template-columns: 96% 4%;
}

.formFieldWrapper input {
  padding-right: .4rem;
}

.formFieldWrapper .copy {
  position: absolute;
  margin: 0;
  border-radius: 100%;
  padding: .03rem;
  right: .1rem;
  z-index: 1;
  fill: #fff;
}