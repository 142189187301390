.otpField {
  position: relative;
  width: 100%;
  margin-bottom: .2rem;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.otpField .otpFieldLabel {
  width: 100%;
  display: block;
}

.otpField .otpFieldLabel span {
  color: #0f161f;
}

.otpField .otpFieldWrapper {
  display: grid;
  grid-template-columns: 58% 40%;
  gap: .12rem;
}

.otpField .otpButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-template-columns: unset;
  height: 100%;
}

.otpField .otpButtonContainer .otpButton {
  width: 100%;
  height: 100%;
  border-radius: .05rem;
}

.otpField .otpButtonContainer .otpButton:disabled {
  opacity: .5;
}

.otpField.horizontal {
  display: grid;
  grid-template-columns: 40% 1fr;
}

.errorValidation.horizontal {
  grid-column: 2/3;
}